import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Input from "reusecore/src/elements/Input";
import Container from "common/src/components/UI/Container";

import NewsletterWrapper, { ContactFormWrapper } from "./newsletter.style";

function Newsletter({ sectionWrapper, textArea, buttonArea, buttonStyle, title, description }) {
  const [formData, setFormData] = useState({
    email: ""
  });

  const validateEmail = email => {
    var re = /\S+@\S+\.\S+/;

    return re.test(email);
  };

  const updateFormData = e => {
    setFormData({
      ...formData,
      email: e
    });
  };

  const handleSubmit = e => {
    if (!formData.email) {
      alert("Email field is required.");
    } else if (!validateEmail(formData.email)) {
      alert("The Email address you entered is invalid.");
    } else {
    }
  };

  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <NewsletterWrapper>
          <Box {...textArea}>
            <Heading content="Subscribe our newsletter" {...title} />
            <Text content="Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore" {...description} />
          </Box>
          <Box {...buttonArea}>
            <ContactFormWrapper>
              <Input inputType="email" label="Email address" iconPosition="right" isMaterial={true} className="email_input" arial-label="email" onChange={updateFormData} />
              <Button {...buttonStyle} title="GET ACCESS" onClick={handleSubmit} />
            </ContactFormWrapper>
          </Box>
        </NewsletterWrapper>
      </Container>
    </Box>
  );
}

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object
};

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ["40px", "40px", "40px", "0", "0"],
    pr: ["0", "0", "0", "80px", "100px"]
  },
  title: {
    fontSize: ["18px", "20px", "22px", "24px", "26px"],
    fontWeight: "500",
    color: "#fff",
    lineHeight: "1.34",
    mb: ["14px", "14px", "14px", "14px", "13px"],
    textAlign: ["center", "center", "center", "left", "left"],
    letterSpacing: "-0.025em"
  },
  description: {
    fontSize: ["14px", "14px"],
    fontWeight: "400",
    color: "#fefefe",
    lineHeight: "1.7",
    mb: 0,
    textAlign: ["center", "center", "center", "left", "left"]
  },
  buttonArea: {
    zIndex: 1
  },
  buttonStyle: {
    type: "button",
    fontSize: "14px",
    fontWeight: "700",
    pl: "30px",
    pr: "30px",
    colors: "secondaryWithBg",
    color: "#03103b"
  }
};

export default Newsletter;
